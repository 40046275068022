<!-- 巡检单管理 -->
<template>
  <div id="inspection-form">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>巡检单管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="场站名称">
              <el-select filterable  v-model="requestParam.stationId" :popper-append-to-body="false" clearable
                placeholder="请选择场站进行查询">
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName+'  '+ item.stationNumber"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="巡检单名称">
              <el-input v-model.trim="requestParam.operationFormName" placeholder="请输入名称查询" clearable>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>

            <el-button v-if="isButtonPower('createButtonInspectionForm')" type="primary" icon="el-icon-plus"
              @click="createInspection">新建巡检单</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="场站名称">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编号">
          </el-table-column>
          <el-table-column align="center" prop="operationFormNum" label="编号">
          </el-table-column>
          <el-table-column align="center" prop="operationFormName" label="名称">
          </el-table-column>

          <el-table-column align="center" prop="remark" label="备注" width="200">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.remark)">
                <el-tooltip :content="scope.row.remark" placement="top" :popper-class="'tooltip'">
                  <div class="ellipsis">{{ scope.row.remark }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.remark }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" width="175" label="创建时间">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate">
            <template slot-scope="scope">
              <div class="operate">
                <div>
                  <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                </div>
                <div v-if="isButtonPower('editButtonInspectionForm')">
                  <span>|</span>
                  <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
                </div>
                <div v-if="isButtonPower('copyButtonInspectionForm')">
                  <span>|</span>
                  <el-button type="text" @click="copyDialog(scope.row)">复制</el-button>
                </div>
                <div v-if="isButtonPower('deleteButtonInspectionForm')">
                  <span>|</span>
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="requestType === 0 ? '新建巡检单' : '修改巡检单'" :visible.sync="dialogVisible" width="751px"
      @close="dialogClose('inspectionForm')">
      <div class="dialog-content">
        <div class="form-title">基本信息</div>
        <el-form class="dialog-form" ref="inspectionForm" :inline="true" :model="selectInspection" :rules="rules">
          <el-form-item  label="变配电站" prop="stationId">
              <el-input class="stationBtn"  placeholder="请选择变配电站" v-model="stationName" :readonly="true">
                <template slot="append">
                  <span @click="selectStation" class="appendSlot">选择配电站</span>
                </template>
              </el-input>
            </el-form-item>
          <el-form-item label="巡检单编号" v-if="requestType === 1">
            <el-input class="stationNumber" :readonly="true" placeholder="请输入备注"  v-model.trim="operationFormNum"></el-input>
          </el-form-item>
          <el-form-item label="巡检单名称" prop="operationFormName">
            <el-input class="stationNumber" :disabled="selectInspection.useFlage !== 0" placeholder="请输入巡检单名称"
              v-model.trim="selectInspection.operationFormName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="场站名称" prop="stationId">
            <el-select v-model="selectInspection.stationId" :popper-append-to-body="false" clearable
              placeholder="请选择场站进行查询" :disabled="selectInspection.useFlage !== 0">
              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" placeholder="请输入备注" :rows="3" v-model.trim="selectInspection.remark"></el-input>
          </el-form-item>

          <div class="interval"></div>
          <div class="form-title">巡检项内容：</div>
          <div v-if="selectInspection.useFlage === 0">
            <div v-show="inspectionItem.length === 0" class="no-data">
              暂无数据
            </div>
            <el-form-item prop="selectInspection" v-show="inspectionItem.length !== 0"
              v-for="(item, index) in inspectionItem" :key="index">
              <div class="select">
                <div class="select-title" :class="item.showStatus
            ? 'selected-inspection'
            : 'default-inspection'
            " @click="selectChange(item)">
                  <span>{{ item.inspectionName }}</span>
                  <div><i class="el-icon-arrow-down"></i></div>
                </div>

                <div v-show="item.showStatus &&
            item.operationInspectionItemPojos.length !== 0
            " class="select-content" v-for="(element, key) in item.operationInspectionItemPojos" :key="key">
                  <span>{{ element.itemName }}</span>
                  <el-checkbox v-model="element.checked"></el-checkbox>
                </div>
                <div v-show="item.showStatus &&
            item.operationInspectionItemPojos.length === 0
            " class="no-data">
                  暂无数据
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-else>
            <div v-show="selectedInspection.length === 0" class="no-data">
              暂无数据
            </div>
            <el-form-item v-show="selectedInspection.length !== 0" v-for="item in selectedInspection" :key="item.id">
              <div class="select">
                <div class="select-title" :class="item.showStatus
            ? 'selected-inspection'
            : 'default-inspection'
            " @click="selectChange(item)">
                  <span>{{ item.inspectionName }}</span>
                  <div><i class="el-icon-arrow-down"></i></div>
                </div>

                <div v-show="item.showStatus &&
            item.operationInspectionItemPojos.length !== 0
            " class="select-content" v-for="element in item.operationInspectionItemPojos" :key="element.id">
                  <span>{{ element.itemName }}</span>
                </div>
                <div v-show="item.showStatus &&
            item.operationInspectionItemPojos.length === 0
            " class="no-data">
                  暂无数据
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('inspectionForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="viewVisible" width="751px">
      <div class="dialog-content">
        <div class="form-title">基本信息</div>
        
        <el-form class="dialog-form" ref="form" :inline="true" :model="detailsData">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="巡检单名称">
            <el-input  v-model="detailsData.operationFormName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="巡检单编号">
            <el-input  v-model="detailsData.operationFormNum" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="备注">
            <el-input :readonly="true" type="textarea" autosize v-model="detailsData.remark"></el-input>
          </el-form-item>
          <div class="interval"></div>
          <div class="form-title">巡检项内容：</div>
          <div v-show="selectedInspection.length === 0" class="no-data">
            暂无数据
          </div>
          <el-form-item v-show="selectedInspection.length !== 0" v-for="item in selectedInspection" :key="item.id">
            <div class="select">
              <div class="select-title" :class="item.showStatus ? 'selected-inspection' : 'default-inspection'
            " @click="selectChange(item)">
                <span>{{ item.inspectionName }}</span>
                <div><i class="el-icon-arrow-down"></i></div>
              </div>

              <div v-show="item.showStatus &&
            item.operationInspectionItemPojos.length !== 0
            " class="select-content" v-for="element in item.operationInspectionItemPojos" :key="element.id">
                <span>{{ element.itemName }}</span>
              </div>
              <div v-show="item.showStatus &&
            item.operationInspectionItemPojos.length === 0
            " class="no-data">
                暂无数据
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button> -->
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该巡检单吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteInspectionForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="复制巡检单" :visible.sync="copyVisible" width="600px" @close="dialogClose('inspectionForm')">
      <div class="medium-dialog">
        <el-form class="dialog-form" ref="inspectionForm" :inline="true" :model="selectInspection" :rules="rules">
          <el-form-item  label="变配电站" prop="stationId">
              <el-input class="stationBtn"  placeholder="请选择变配电站" v-model="stationName" :readonly="true">
                <template slot="append">
                  <span @click="selectStation" class="appendSlot">选择配电站</span>
                </template>
              </el-input>
            </el-form-item>
          <el-form-item label="巡检单名称" prop="operationFormName">
            <el-input v-model.trim="selectInspection.operationFormName"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyVisible = false">取 消</el-button>
        <el-button type="primary" @click="copyConfirm('inspectionForm')">确 定</el-button>
      </div>
    </el-dialog>
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
import { inspectionFormApi } from "@/api/inspectionForm.js";
import stationDialog from "./commponents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        stationId: "",
        operationFormName: "",
      },
      stationName:'',
      total: 0,
      inputValue: "",
      dialogStatus: false,
      stationId: 0,
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      copyVisible: false,
      detailsData: {},
      selectInspection: {
        operationFormName: "",
        stationId: "",
        remark: "",
        useFlage: 0,
      },
      defaultInspection: {
        operationFormName: "",
        stationId: "",
        remark: "",
        useFlage: 0,
      },
      id: "",
      inspectionItem: [],
      stationData: [],
      requestType: 0,
      operationFormNum:'',
      rules: {
        operationFormName: [
          { required: true, message: "请输入巡检单名称", trigger: "blur" },
        ],
        stationId: [
          { required: true, message: "请选择场站", trigger: "change" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      selectedInspection: [],
      allButtonPower: [],
    };
  },
  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "巡检单管理");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryInspectionForm();
    this.queryStationName();
    this.queryInspectionItem();
    this.allButtonPower = sessionStorage.getItem('roleInfo');
    console.log("!!!!!!!!", this.allButtonPower)
  },

  methods: {
    selectChange(item) {
      item.showStatus = !item.showStatus;
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    selectStation() {
     
     this.dialogStatus = true;
   },
    getStationName(value) {
      console.log("11111",value);
      if(value.id){

        this.selectInspection.stationId=value.id
        this.stationId=value.id
        // this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
      }
      console.log(this.selectInspection);
      this.dialogStatus = false;
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    isOverflow(content) {
      if (content) {
        const lineHeight = 16;
        const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
        // console.log("====>",numLines);
        return numLines >= 3;
      }
    },
    dialogClose(formName) {
      Object.assign(this.selectInspection, this.defaultInspection);
      this.stationName=''
      this.stationId=0
      this.$refs[formName].resetFields();
      // this.selectInspection=this.$options.data().selectInspection
      this.inspectionItem.forEach((element) => {
        element.showStatus = false;
        element.operationInspectionItemPojos.forEach((item) => {
          item.checked = false;
        });
      });
    },
    createInspection() {
      this.requestType = 0;
      this.dialogVisible = true;
    },
    updateDialog(item) {
      this.stationName=item.stationName
      this.stationId=item.stationId
      this.requestType = 1;
      this.id = item.id;
      this.queryInspectionDetails();
      this.selectInspection.operationFormName = item.operationFormName;
      this.selectInspection.remark = item.remark;
      this.selectInspection.stationId = item.stationId;
      this.operationFormNum=item.operationFormNum
      this.dialogVisible = true;
    },
    viewDialog(item) {
      this.requestType = 2;
      this.id = item.id;
      this.queryInspectionDetails();
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    copyDialog(item) {
      this.requestType = 3;
      this.id = item.id;
      this.selectInspection.operationFormName = "";
      this.queryInspectionDetails();
      this.copyVisible = true;
    },
    copyConfirm(formName) {
      console.log( this.selectInspection);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let inspectionItem = [];
          this.selectedInspection.forEach((item) => {
            item.operationInspectionItemPojos.forEach((element) => {
              inspectionItem.push({
                operationInspectionId: item.id,
                operationInspectionItemId: element.id,
              });
            });
          });
          this.selectInspection.stationId = this.stationId;
          this.selectInspection.remark = this.detailsData.remark;
          this.selectInspection.id = this.id;
          this.selectInspection.operationInspectionFormItems = inspectionItem;
          console.log( this.selectInspection);
          inspectionFormApi
            .createInspectionForm(this.selectInspection)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: res.message,
                  type: "success",
                  duration: 3000,
                  customClass: "messageText",
                });
                this.copyVisible = false;
                this.queryInspectionForm();
              } else {
                this.$message({
                  message: res.message,
                  type: "warning",
                  duration: 3000,
                  customClass: "messageText",
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    dialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createInspectionForm();
          } else {
            // if (this.selectInspection.useFlage !== 0) {
            //   this.$message({
            //     message:
            //       "该巡检单被使用只能修改备注，可复制此巡检单进行修改使用",
            //     type: "warning",
            //     duration: 3000,
            //     customClass: "messageText",
            //   });
            // }
            this.updateInspectionForm();
          }
        } else {
          return false;
        }
      });
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryInspectionForm();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryInspectionForm();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryInspectionForm();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryInspectionForm();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryInspectionForm();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryInspectionForm();
    },
    queryInspectionItem() {
      inspectionFormApi.queryInspectionItem().then((res) => {
        if (res.code === 200) {
          res.data.forEach((element) => {
            element.showStatus = false;
            element.operationInspectionItemPojos.forEach((item) => {
              item.checked = false;
            });
          });
          this.inspectionItem = res.data;
        }
      });
    },
    // useInspectionForm() {
    //   inspectionFormApi.useInspectionForm(this.id).then((res) => {
    //     if (res.code === 200) {
    //       this.$message({
    //           message: res.message,
    //           type: "error",
    //           duration: 3000,
    //           customClass: "messageText",
    //         });
    //     }else{
    //       this.$message({
    //           message: res.message,
    //           type: "error",
    //           duration: 3000,
    //           customClass: "messageText",
    //         });
    //     }
    //   });
    // },
    queryInspectionDetails() {
      inspectionFormApi.queryInspectionDetails(this.id).then((res) => {
        if (res.code === 200) {
          this.selectedInspection = res.data.operationInspections.map(
            (item) => {
              item.showStatus = false;
              return item;
            }
          );
          this.detailsData = res.data;
          // 修改
          if (this.requestType === 1) {
            let selectInspection = res.data.operationInspections;
            this.selectInspection.useFlage = res.data.useFlage;

            this.inspectionItem.forEach((item) => {
              selectInspection.forEach((element) => {
                if (item.id === element.id) {
                  item.operationInspectionItemPojos.forEach((all) => {
                    element.operationInspectionItemPojos.forEach((select) => {
                      if (all.id === select.id) {
                        all.checked = true;
                      }
                    });
                  });
                }
              });
            });
          }
        }
      });
    },
    queryInspectionForm() {
      inspectionFormApi.queryInspectionForm(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    createInspectionForm() {
      let inspectionItem = [];
      this.inspectionItem.forEach((item) => {
        item.operationInspectionItemPojos.forEach((element) => {
          if (element.checked) {
            inspectionItem.push({
              operationInspectionId: item.id,
              operationInspectionItemId: element.id,
            });
          }
        });
      });
      this.selectInspection.operationInspectionFormItems = inspectionItem;
      if (inspectionItem.length === 0) {
        this.$message({
          message: "请选择巡检内容",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      inspectionFormApi
        .createInspectionForm(this.selectInspection)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.dialogVisible = false;
            this.queryInspectionForm();
          } else {
            this.$message({
              message: res.message,
              type: "warning",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    updateInspectionForm() {
      let inspectionItem = [];
      if (this.selectInspection.useFlage === 0) {
        this.inspectionItem.forEach((item) => {
          item.operationInspectionItemPojos.forEach((element) => {
            if (element.checked) {
              inspectionItem.push({
                operationInspectionId: item.id,
                operationInspectionItemId: element.id,
              });
            }
          });
        });
      } else {
        this.selectedInspection.forEach((item) => {
          item.operationInspectionItemPojos.forEach((element) => {
            inspectionItem.push({
              operationInspectionId: item.id,
              operationInspectionItemId: element.id,
            });
          });
        });
      }
      this.selectInspection.operationInspectionFormItems = inspectionItem;
      this.selectInspection.id = this.id;
      if (inspectionItem.length === 0) {
        this.$message({
          message: "请选择巡检内容",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      inspectionFormApi
        .updateInspectionForm(this.selectInspection)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.dialogVisible = false;
            this.queryInspectionForm();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    queryStationName() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    deleteInspectionForm() {
      inspectionFormApi.deleteInspectionForm(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryInspectionForm();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#inspection-form {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* 弹窗 */
.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-content {
  margin: 18px 50px 0 47px;
}

.dialog-form :deep() .el-input .el-input__inner,
.dialog-form :deep() .el-textarea textarea {
  width: 563px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

/*  */
.select-title {
  width: 651px;
  height: 40px;
  border: 1px solid #09295b;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
}

.select-title span {
  font-family: "Arial", sans-serif;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 40px;
}

.select-content {
  padding: 0 16px;
  background-color: #041c4c;
  border-bottom: 1px solid #06224e;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.select-content span {
  color: #aed6ff;
  font-size: 12px;
}

.selected-inspection {
  background-color: #041c4c;
}

.no-data {
  height: 60px;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  background-color: #041c4c;
  margin: 16px 0;
}

.medium-dialog {
  margin: 30px 32px 0 27px;
}

.medium-dialog .dialog-form :deep() .el-input {
  width: 406px;
}

.medium-dialog .dialog-form :deep() .el-input .el-input__inner {
  width: 100%;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}
.stationBtn >>> .el-input__inner{
  width: 330px !important;
  overflow: hidden;
  height: 40px;
}
.stationNumber >>> .el-input__inner{
  /* width: 330px !important;
  overflow: hidden; */
  height: 40px;
}
::v-deep .el-input-group__append{
  background: #143168;
  border-color: #143168;
  color: #fff;
  cursor: pointer;
}
.appendSlot{

}
</style>
<style>
.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}
</style>
